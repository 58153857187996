
					@import './src/sass/variables';
					@import './src/sass/mixins';
				

































































































































































































label:not(.bool-input) {
	display: block;
	margin-bottom: 10px;
}

.logotype-wrapper {
	display: flex;
	flex-flow: row wrap;
	margin-bottom: $default_padding;

	.dropzone {
		flex: 1;
	}

	.current-file {
		margin-right: $default_padding;
		max-height: 200px;
		align-self: center;
	}
}

.form-group {
	margin-bottom: $default_padding;
}

[data-append-field] {
	display: flex;
	background: $color__white;
	box-shadow: $box_shadow__input;
	border-radius: $border_radius;
	text-align: right;

	input {
		margin-bottom: 0;
		padding-right: 0.25rem;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		text-align: inherit;

		&:not(:focus) {
			box-shadow: none;
		}
	}

	&::after {
		content: attr(data-append-field);
		font-size: 1.25rem;
		line-height: 1em;
		padding: 0.5rem 1rem 0.5rem 0.25rem;
		opacity: 0.5;
	}
}

.columns {
	display: flex;
	gap: 20px;

	& > * {
		flex: 1;
	}
}
